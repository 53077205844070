import {
  CalendarOutlined,
  SearchOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Image,
  Tooltip,
  Input,
  Row,
  Select,
  Space,
  Table,
  Typography,
} from "antd";
import dayjs from "dayjs";
import React, { useEffect, useMemo, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "../assets/logo.svg";
import { AREA_URL, WEB_URL } from "./web-config";

const { Text } = Typography;
const { RangePicker } = DatePicker;

const KnowledgeBase = () => {
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [searchInput, setSearchInput] = useState();
  const [status, setStatus] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [areaData, setAreaData] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [count, setCount] = useState();

  useEffect(() => {
    setLoading(true);
    fetch(`${AREA_URL}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        return response.json();
      })
      .then((data) => {
        data.status === 200 && setLoader(false);
        setAreaData(data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error, "error");
        setLoading(false);
      });
  }, []);

  const areaConcern = [];
  areaData &&
    areaData.length > 0 &&
    areaData.map((data, index) => {
      areaConcern.push({
        key: index,
        label: data.name,
        value: data.id,
      });
    });

  const fetchData = (offset, searchInput, status, start_date, end_date) => {
    setLoader(true);
    const url = `${WEB_URL}/knowledge-base?limit=10&offset=${offset}${
      searchInput ? "&keyword=" + searchInput : ""
    }${status ? "&areasOfConcernId=" + status : ""}${
      start_date ? "&start_date=" + start_date : ""
    }${end_date ? "&end_date=" + end_date : ""}`;

    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        return response.json();
      })
      .then((data) => {
        setData(data.data);
        // setCount(data)
        setCount(data.total_records);
      })
      .catch((error) => {
        console.error(error, "error");
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const dataList = [];

  data &&
    data.map((e, index) => {
      dataList.push({
        key: index,
        id: e.kb_id,

        areaofconcernName: (
          <div>
            {e.areaofconcernName == ""
              ? "-"
              : e.areaofconcernName
              ? e.areaofconcernName
              : "-"}
          </div>
        ),
        title: <div className="font-semibold">{e.title ? e.title : "-"} </div>,
        created_at: (
          <Text>
            {e.created_at ? (
              <>
                <div className="flex justify-center items-center">
                  <Text strong className="ml-1 main-custom">
                    {dayjs(e.created_at).format("DD/MM/YYYY")}
                  </Text>
                </div>
              </>
            ) : (
              "-"
            )}{" "}
          </Text>
        ),
      });
    });
  // setData(dataList);

  useEffect((e) => {
    fetchData(0);
  }, []);

  const onRangeChange = (dates, dateStrings) => {
    if (dates) {
      setStartDate(dateStrings[0]);
      setEndDate(dateStrings[1]);
    } else {
      setStartDate("");
      setEndDate("");
    }
  };

  const onFinishSearch = (e) => {
    fetchData(
      0,
      e.keyword ? e.keyword : "",
      e.status ? e.status : "",
      startDate ? startDate : "",
      endDate ? endDate : ""
    );
  };

  const handleCHange = (e) => {
    console.log(e);
    setSearchInput(e.target.value);
  };

  const onChange = (e) => {
    setStatus(e);
  };

  const onClickPaginate = (e) => {
    setCurrentPage(e - 1);
    fetchData(
      e.current - 1,
      searchInput ? searchInput : "",
      status ? status : "",
      startDate ? startDate : "",
      endDate ? endDate : ""
    );
  };
  const columns = [
    {
      title: "Module",
      dataIndex: "areaofconcernName",
      key: "areaofconcernName",
      // width: 120,
      // responsive: ['md'],
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      ellipsis: true,
    },
    {
      title: (
        <div className="flex justify-center items-center m-auto">
          <CalendarOutlined />
          <div className=" ml-1 flex justify-center items-center main-custom ">
            Submission Date
          </div>
        </div>
      ),
      dataIndex: "created_at",
      key: "created_at",
      // width: 200,
      // responsive: ['md'],
    },
    {
      title: "Action",
      fixed: "right",
      align: "center",
      width: "60px",
      key: "action",
      render: (item) => (
        <>
          <Space size="middle">
            <Link
            target="_blank"
              to={`/knowledge-base/${item.id}`}
              // onClick={(id) => showModal(item.id)}
              style={{ color: "black" }}
            >
              <Tooltip placement="top" title={"View Knowledge Base"}>
                <EyeOutlined
                  style={{
                    color: "#265BA6",
                    fontSize: "18px",
                    // marginRight: 5,
                  }}
                />
              </Tooltip>
            </Link>
          </Space>
        </>
      ),
    },
  ];

  return (
    <>
      <div className="border-b border-b-[#EAEAEA] flex items-center">
        <div className="lg:w-[70%]   mx-auto">
          <div className="text-center  flex  justify-center space-x-8 items-center custom-padding py-2">
            <div className="flex text-center items-center">
              <NavLink to="/">
                {" "}
                <Image src={logo} preview={false} className="logo"  />
              </NavLink>{" "}
              <p className="text-3xl font-semibold maintext">
                SAP Ticket Submission Form
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className=" ">
        <div className="">
          <div className="mt-5">
            <Row align="middle" justify="">
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={15}
                xl={15}
                className="mx-auto lg:px-0 px-2"
              >
                <Form
                  className="custom-form"
                  name="basic"
                  layout="inline"
                  onFinish={onFinishSearch}
                  autoComplete="off"
                >
                  <Form.Item name="keyword" className="main-input">
                    <Input
                      allowClear
                      onChange={handleCHange}
                      style={{ width: "100%" }}
                      autoFocus="none"
                      type={"text"}
                      //onChange={(e) => onchangeSearch(e)}
                      prefix={
                        <SearchOutlined className="site-form-item-icon" />
                      }
                      placeholder="Search Here"
                    />
                  </Form.Item>
                  <Form.Item
                    name="status"
                    className="main-input   md:pt-1 lg:pt-0"
                  >
                    <Select
                      showSearch
                      onChange={onChange}
                      className="user rate-select"
                      placeholder="Select Module"
                      optionFilterProp="children"
                      allowClear
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={areaConcern}
                    />
                  </Form.Item>
                  <Form.Item name="date" className="main-input">
                    <RangePicker
                      onChange={onRangeChange}
                      className="range-date"
                    />
                  </Form.Item>

                  <Form.Item className="lg:mt-0 xl:mt-1">
                    <Button
                      className="bg-white search-btn user"
                      type="primary"
                      htmlType="submit"
                    >
                      Search
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <Row className="mx-auto w-[100%] mt-5 lg:px-0 px-2  ">
        <Col xs={24} sm={24} className="mx-auto" lg={15} xl={15}>
          <Table
            className="custom-table"
            loading={loader}
            columns={columns}
            onChange={(e) => onClickPaginate(e)}
            dataSource={dataList}
            pagination={{ total: count, hideOnSinglePage: true }}
            size="small"
          />{" "}
        </Col>
      </Row>
    </>
  );
};

export default KnowledgeBase;
