import React, { useEffect, useRef } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Editor } from "@tinymce/tinymce-react";
import {
  Button,
  Col,
  Form,
  Image,
  Input,
  Modal,
  Row,
  Select,
  Upload,
  message,
} from "antd";
import { Link, NavLink } from "react-router-dom";
import ImgCrop from "antd-img-crop";
import { useState } from "react";
import logo from "../assets/logo.svg";
import { WEB_URL, BASE_URL_UPLOAD, AREA_URL } from "./web-config";

const { TextArea } = Input;

const getBase64 = (img) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result));
    reader.addEventListener("error", (error) => reject(error));
    reader.readAsDataURL(img);
  });
};

const RequestForm = () => {
  const [form] = Form.useForm();
  const [imageFileList, setImageFileList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [descriptionError, setDescriptionError] = useState(false);

  const [content, setContent] = useState("");
  const editorRef = useRef(null);

  useEffect(() => {
    setLoading(true);
    fetch(`${AREA_URL}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        return response.json();
      })
      .then((data) => {
        data.status === 200 && setLoader(false);
        setData(data.data);
      })
      .catch((error) => {
        console.error(error, "error");
      });
  }, []);

  const log = () => {
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
    }
  };

  const areaConcern = [];
  data &&
    data.length > 0 &&
    data.map((data, index) => {
      areaConcern.push({
        key: index,
        label: data.name,
        value: data.id,
      });
    });

  const handleEditorChange = (newContent, editor) => {
    // Check if the new content is of type string
    if (typeof newContent === "string") {
      setContent(newContent);
      // setDescriptionError("");
    } else {
      setContent("");
    }
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      try {
        const preview = await getBase64(file.originFileObj);
        file.preview = preview;
      } catch (error) {
        console.error("Error while getting the preview image:", error);
        return;
      }
    }

    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const handleCancel = () => setPreviewOpen(false);
  const handleChangeImage = ({ fileList: newFileList }) => {
    // let updatedFileList = [...newFileList];
    const maxSize = 100;

    console.log(newFileList, "handleChangeImage");

    const imageFormat = /\.(jpe?g|png)$/i;
    const allFormat = /\.(pdf|csv|xls[x]?|doc[x]?)$/i;

    const updatedFileList = newFileList.filter((file) => {
      const fileSize = file.size / 1024 / 1024;

      if (
        (file.type.startsWith("image/") && imageFormat.test(file.name)) ||
        allFormat.test(file.name)
      ) {
        if (fileSize <= maxSize) {
          return true;
        } else {
          message.error("Maximum image size allowed is 10MB!", 2);
          return false;
        }
      } else {
        message.error(
          "Please upload file/image with (jpg/png/doc/pdf/csv/xlsx) extensions only.",
          2
        );
        return false;
      }
    });

    setImageFileList(updatedFileList);
  };

  const uploadButton = (
    <div className="flex justify-center items-center ">
      <PlusOutlined />
      <div
        style={{
          marginLeft: 5,
          letterSpacing: 0.5,
        }}
      >
        Upload
      </div>
    </div>
  );

  const onReset = () => {
    form.resetFields();
    setImageFileList([]);
  };

  const onFinish = (values) => {
    console.log(content);
    const imagesArr = [];

    imageFileList &&
      imageFileList.length > 0 &&
      imageFileList.map((d) => {
        if (d.response) {
          return imagesArr.push(d.response.file.filename);
        } else if (d.is_cropper_image) {
          return imagesArr.push(d.is_cropper_image);
        } else return imagesArr.push(d.filename);
      });

    form
      .validateFields()
      .then(() => {
        setLoader(true);
        fetch(`${WEB_URL}/tickets`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...values,
            description: content,
            attachemnets: imagesArr,
          }),
        })
          .then((response) => response.json())
          .then((responseData) => {
            if (parseInt(responseData.status) === 200) {
              setLoader(false);
              message.success(responseData.message);
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
              form.resetFields();
              setImageFileList([]);
              // setContent("");
              // Handle the response data
            } else {
              setLoader(false);
              message.error(responseData.message);
            }
          })
          .catch((error) => {
            setLoader(false);
            message.error(error.message);
            // Handle any errors
            console.error(error);
          });
      })
      .catch((errorInfo) => {
        console.log(errorInfo);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <>
      <div className="border-b border-b-[#EAEAEA] flex items-center">
        <div className="lg:w-[70%]   mx-auto">
          <div className="text-center  flex  justify-center space-x-8 items-center custom-padding py-2">
            <div className="flex text-center items-center">
              <NavLink to="/">
                {" "}
                <Image src={logo} preview={false} />
              </NavLink>
              <p className="text-3xl font-semibold maintext">
                SAP Ticket Submission Form
              </p>
            </div>
            {/* <div className="space-y-4">
              <p className="text-3xl font-semibold maintext">
              </p>
            </div> */}
          </div>
        </div>
        <div className="mr-9 text-[#265ba6] underline">
          <Link to="/knowledge-base">Knowledge Base</Link>
        </div>
      </div>
      <div className="bg-[#EDEDED] flex-1 py-10">
        <div className="w-[95%] bg-white rounded-xl mx-auto p-5 lg:w-[40%]">
          <Form
            layout="vertical"
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Row gutter={[16, 0]}>
              <Col xs={24} xl={24}>
                <Form.Item
                  className=" text-black text-base font-medium"
                  name="email"
                  label="Email"
                  rules={[
                    {
                      type: "email",
                      required: !form.getFieldValue("email"),
                      message: "Please input your Email!",
                    },
                  ]}
                >
                  <Input
                    autoComplete="off"
                    placeholder="Enter Your Email"
                    className="rounded-md border border-[#162038] border-opacity-50 border-0.5 bg-[#EDEDED] py-2 text-black"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} xl={24}>
                <Form.Item
                  className=" text-black text-base font-medium"
                  name="title"
                  label="Title"
                  rules={[
                    {
                      // type: "text",
                      required: true,
                      message: "Please input Title!",
                    },
                  ]}
                >
                  <Input
                    autoComplete="off"
                    placeholder="Enter Title"
                    className="rounded-md border border-[#162038] border-opacity-50 border-0.5 bg-[#EDEDED] py-2 text-black"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} xl={24}>
                <Form.Item
                  className=" text-black text-base font-medium custom-label"
                  name="on_behalf_of_name"
                  label="On Behalf Name"
                  rules={[
                    {
                      required: form.getFieldValue('on_behalf_of_email'),
                      // message: "Please input On Behalf Name!",
                      validator: (_, value) => {
                        if (!value && form.getFieldValue('on_behalf_of_email')) {
                          return Promise.reject("Please input On Behalf Name!");
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Input
                    autoComplete="off"
                    placeholder="Enter On Behalf Name"
                    className="rounded-md border border-[#162038] border-opacity-50 border-0.5 bg-[#EDEDED] py-2 text-black"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} xl={24}>
                <Form.Item
                  className=" text-black text-base font-medium custom-label"
                  name="on_behalf_of_email"
                  label="On Behalf Email"
                  rules={[
                    {
                      type: "email",
                      message: "Please input valid email",
                    },
                    {
                      required: form.getFieldValue('on_behalf_of_name'),
                      validator: (_, value) => {
                        if (!value && form.getFieldValue('on_behalf_of_name')) {
                          return Promise.reject("Please input On Behalf Email!");
                        }
                        return Promise.resolve();
                      },
                    }
                  ]}
                >
                  <Input
                    autoComplete="off"
                    placeholder="Enter On Behalf Email"
                    className="rounded-md border border-[#162038] border-opacity-50 border-0.5 bg-[#EDEDED] py-2 text-black"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} xl={24}>
                <Form.Item
                  name="primary_troubleshooting"
                  label="Has the Primary Troubleshooting Done?"
                  className=" text-black text-base font-medium"
                  rules={[
                    {
                      required: true,
                      message: "Please select Primary Troubleshooting",
                    },
                  ]}
                >
                  <Select
                    allowClear
                    className="rounded-md border border-[#162038] border-opacity-50 border-0.5 bg-[#EDEDED] py-1 text-black"
                    placeholder="Choose"
                    //   onChange={handleChange}
                    options={[
                      {
                        value: "YES",
                        label: "YES",
                      },
                      {
                        value: "NO",
                        label: "NO",
                      },
                      {
                        value: "Not Sure how to perform",
                        label: "Not Sure, How To Perform",
                      },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} xl={24}>
                <Form.Item
                  className=" text-black text-base font-medium notRequired"
                  name="primary_troubleshooting_description"
                  label="Please mention the Steps performed in Primary Troubleshooting"
                  rules={[
                    {
                      required: true,
                      message: "Please input Steps Performed!",
                    },
                  ]}
                >
                  <Input
                    autoComplete="off"
                    placeholder="Your answer"
                    className="rounded-md border border-[#162038] border-opacity-50 border-0.5 bg-[#EDEDED] py-2 text-black"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} xl={24}>
                <Form.Item
                  name="rate_issuue"
                  label="Please Rate the Issue according to Priority"
                  className=" text-black text-base font-medium"
                  rules={[
                    {
                      required: true,
                      message: "Please select Priority",
                    },
                  ]}
                >
                  <Select
                    allowClear
                    className="rounded-md border border-[#162038] border-opacity-50 border-0.5 bg-[#EDEDED] py-1 text-black"
                    placeholder="Choose"
                    //   onChange={handleChange}
                    options={[
                      {
                        value: "Normal",
                        label: "Normal",
                      },
                      {
                        value: "Medium",
                        label: "Medium",
                      },
                      {
                        value: "High",
                        label: "High",
                      },
                      {
                        value: "Very High",
                        label: "Very High",
                      },
                      {
                        value: "Urgent",
                        label: "Urgent",
                      },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} xl={24}>
                <Form.Item
                  name="areaofconcernId"
                  label="Area of Concern"
                  className="text-black text-base font-medium"
                  rules={[
                    {
                      required: true,
                      message: "Please select Area of Concern",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    allowClear
                    className="rounded-md border border-[#162038] border-opacity-50 border-0.5 bg-[#EDEDED] py-1 text-black"
                    placeholder="Choose"
                    //   onChange={handleChange}
                    options={areaConcern}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} xl={24}>
                <Form.Item
                  name="typesofissue"
                  label="Type of Issue"
                  className=" text-black text-base font-medium"
                  rules={[
                    {
                      required: true,
                      message: "Please select Type Of issue",
                    },
                  ]}
                >
                  <Select
                    allowClear
                    className="rounded-md border border-[#162038] border-opacity-50 border-0.5 bg-[#EDEDED] py-1 text-black"
                    placeholder="Choose"
                    //   onChange={handleChange}
                    options={[
                      {
                        value: "INCIDENT",
                        label: "Incident",
                      },
                      {
                        value: "PROBLEM",
                        label: "Problem",
                      },
                      {
                        value: "TROUBLESHOOTING",
                        label: "Troubleshooting",
                      },
                      {
                        value: "CUSTOM INTEGRATION",
                        label: "Custom Integration",
                      },
                      {
                        value: "NEW REQUIREMENT",
                        label: "New Requirement",
                      },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} xl={24}>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      validator: (_, value) => {
                        if (!content || content.trim() == "") {
                          return Promise.reject(
                            new Error("Please Describe Issue!")
                          );
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                  className="text-black text-base font-medium"
                  name="description"
                  label="Describe the Issue?"
                  getValueFromEvent={(event, editorValue) => editorValue} // Pass the editor value to the form
                  // initialValue={content} // Set the initial value of the form item
                >
                  <Editor
                    apiKey="hglsma9tuhg1k0s3lopkfjuqpv6uhaa4qiv8sbgnbepck75f" // Replace 'your-api-key' with your TinyMCE API key
                    onEditorChange={handleEditorChange}
                    init={{
                      height: 350,
                      branding: false,
                      plugins:
                        "print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons",
                      imagetools_cors_hosts: ["picsum.photos"],
                      menubar: "file edit view insert format tools table help",
                      toolbar:
                        "undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl",
                      toolbar_sticky: true,
                      autosave_interval: "30s",
                      autosave_ask_before_unload: true,
                    }}
                    wrapperClassName="demo-wrapper"
                    editorClassName="demo-editor"
                    ref={editorRef} // Set a reference to the editor instance
                  />
                  {descriptionError && (
                    <div className="text-red-500 mt-1">
                      Please Describe Issue!
                    </div>
                  )}
                  <div className="hidden">
                    <h2>Editor Content:</h2>
                    {content ? (
                      <div dangerouslySetInnerHTML={{ __html: content }} />
                    ) : null}
                  </div>
                </Form.Item>
              </Col>
              <Col xs={24} xl={24}>
                <Form.Item
                  className="attatchment-input custom-label"
                  label="Kindly attach file/image with (jpg/png/doc/pdf/csv/xlsx):                  "
                  name="attachemnets"
                  rules={[
                    {
                      required: imageFileList ? false : true,
                      message: "Screenshot is required!",
                    },
                  ]}
                  // rules={[
                  //   {
                  //     required: imageFileList.length === 0,
                  //     message: "Screenshot is required!",
                  //   },
                  // ]}
                >
                  <div>
                    {/* <ImgCrop rotationSlider> */}
                    <Upload.Dragger
                      className="custom-dragger"
                      name="file"
                      action={BASE_URL_UPLOAD}
                      listType="picture-card"
                      fileList={imageFileList}
                      onDrop={() => console.log("tes")}
                      onChange={handleChangeImage}
                      onPreview={handlePreview}
                    >
                      {uploadButton}
                    </Upload.Dragger>
                    {/* </ImgCrop> */}
                    <Modal
                      open={previewOpen}
                      title={previewTitle}
                      footer={null}
                      onCancel={handleCancel}
                    >
                      <img
                        alt="example"
                        style={{
                          width: "100%",
                        }}
                        src={previewImage}
                      />
                    </Modal>
                  </div>
                </Form.Item>
              </Col>
            </Row>
            <div className="flex justify-center items-baseline py-16">
              <Form.Item>
                <Button
                  loading={loader}
                  htmlType="submit"
                  className="flex items-center justify-center mr-5  w-sm-25 w-25 md:w-50 lg:w-75 h-12 text-black font-[700] text-[16px]"
                >
                  Submit
                </Button>
              </Form.Item>
              <Button
                htmlType="button"
                onClick={onReset}
                className=" reset-btn items-center justify-center w-25 h-12 font-[700]  w-sm-25 w-25 md:w-50 lg:w-70 text-[16px] bg-white text-[#265ba6 ] hover:bg-blue-500 hover:text-white"
              >
                Clear Form
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default RequestForm;
