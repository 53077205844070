import { Image, Spin, Typography } from "antd";
import { CalendarOutlined, FileTextOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import logo from "../assets/logo.svg";
import { KB_URL, WEB_URL } from "./web-config";

function KnowledgeBaseArticle() {
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState();
  const { kb_id } = useParams();

  useEffect(() => {
    setLoader(true);
    fetch(`${WEB_URL}/knowledge-base/${kb_id}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        return response.json();
      })
      .then((data) => {
        data.status === 200 && setLoader(false);
        setData(data.data);
      })
      .catch((error) => {
        console.error(error, "error");
      })
      .finally(() => {
        setLoader(false); // Regardless of success or failure, set loader to false
      });
  }, [kb_id]);

  return (
    <>
      <div className="border-b border-b-[#EAEAEA] flex items-center">
        <div className="lg:w-[70%]   mx-auto">
          <div className="text-center  flex  justify-center space-x-8 items-center custom-padding py-2">
            <div className="flex text-center items-center">
              <NavLink to="/">
                {" "}
                <Image src={logo} preview={false} className="logo" />
              </NavLink>{" "}
              <p className="text-3xl font-semibold maintext">
                SAP Ticket Submission Form
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="w-[95%] bg-[#ffffff] rounded-xl mx-auto p-5 lg:w-[40%]">
        <Spin spinning={loader}>
          <div className="mt-5">
            <h2 className="text-center text-2xl">{data?.title}</h2>
            <br />
            <br />
            <Typography.Text>
              {data && data.created_at ? (
                <>
                  <div className="flex justify-start items-center">
                    <CalendarOutlined />
                    <Typography.Text strong className="ml-1 tracking-wide	">
                      {dayjs(data.created_at).format("DD MMMM YYYY, hh:mm A")}
                    </Typography.Text>
                  </div>
                </>
              ) : (
                "-"
              )}
            </Typography.Text>
            <div className="container mx-auto text-lg mt-5">
              <div
                dangerouslySetInnerHTML={{
                  __html: data && data.description ? data.description : "-",
                }}
              ></div>
            </div>
          </div>
        </Spin>
      </div>
    </>
  );
}

export default KnowledgeBaseArticle;
